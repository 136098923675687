export const omit = <T extends Record<string, unknown>>(
  obj: T,
  paths: string[]
) => {
  const removeNested = (
    obj: Record<string, unknown>,
    path: string[]
  ): Record<string, unknown> => {
    const [first, ...rest] = path

    if (rest.length === 0) {
      const { [first]: _, ...remaining } = obj
      return remaining
    }

    if (obj[first] && typeof obj[first] === 'object') {
      return {
        ...obj,
        [first]: removeNested(obj[first] as Record<string, unknown>, rest),
      }
    }

    return obj
  }

  return paths.reduce<Record<string, unknown>>(
    (result, path) => removeNested(result, path.split('.')),
    { ...obj }
  )
}

export const pick = <T extends Record<string, unknown>>(
  obj: T,
  paths: string[]
) => {
  const pickNested = (
    obj: Record<string, unknown>,
    path: string[]
  ): Record<string, unknown> => {
    const [first, ...rest] = path

    if (rest.length === 0) {
      if (!(first in obj)) return {}
      return { [first]: obj[first] }
    }

    if (obj[first] && typeof obj[first] === 'object') {
      const nested = pickNested(obj[first] as Record<string, unknown>, rest)
      return Object.keys(nested).length ? { [first]: nested } : {}
    }

    return {}
  }

  return paths.reduce<Record<string, unknown>>(
    (result, path) => ({
      ...result,
      ...pickNested(obj, path.split('.')),
    }),
    {}
  )
}
