import { ColorWhite, ColorBlack } from '@gr4vy/poutine-tokens'
import Color from 'colorjs.io'
import { DEFAULT_BASE_COLOR } from './constants'
import { getLightnessContrast } from './get-lightness-contrast'

export const WHITE_COLOR = new Color(ColorWhite)
export const BLACK_COLOR = new Color(ColorBlack)

export const getButtonTextColor = (merchantColor?: string) => {
  const backgroundColor = new Color(merchantColor ?? DEFAULT_BASE_COLOR)
  const idle =
    getLightnessContrast(WHITE_COLOR, backgroundColor) >
    getLightnessContrast(BLACK_COLOR, backgroundColor)
      ? WHITE_COLOR
      : BLACK_COLOR

  const hover = new Color(idle).darken(0.65)

  return {
    idle,
    hover,
  }
}
