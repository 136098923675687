import Gr4vyEmbed, { EmbedInstance } from '@gr4vy/embed-react'
import { memo, RefObject } from 'react'
import { env } from '@/utils'

const Embed = ({
  forwardedRef,
  gr4vyId,
  environment,
  amount,
  buyer,
  cartItems,
  country,
  currency,
  externalIdentifier,
  intent,
  metadata,
  paymentSource,
  statementDescriptor,
  merchantAccountId,
  token,
  onEvent,
  theme,
}: Record<string, unknown>) => {
  const {
    VITE_API_HOST: apiHost,
    VITE_IFRAME_HOST: iframeHost,
    VITE_SECURE: secure,
  } = env

  return (
    <Gr4vyEmbed
      ref={forwardedRef as RefObject<EmbedInstance>}
      gr4vyId={apiHost && iframeHost ? undefined : gr4vyId}
      secure={typeof secure === 'string' ? secure === 'true' : true}
      environment={environment}
      amount={amount}
      buyer={buyer}
      cartItems={cartItems}
      country={country}
      currency={currency}
      externalIdentifier={externalIdentifier}
      intent={intent}
      metadata={metadata}
      paymentSource={paymentSource}
      statementDescriptor={statementDescriptor}
      merchantAccountId={merchantAccountId}
      token={token}
      onEvent={onEvent}
      apiHost={env.VITE_API_HOST}
      iframeHost={env.VITE_IFRAME_HOST}
      theme={theme}
    />
  )
}

const MemeEmbed = memo(Embed, (prevProps, nextProps) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps)
})

export { MemeEmbed as Embed }
