import Color from 'colorjs.io'
import { DEFAULT_BASE_COLOR } from './constants'

export const getSpinnerColor = (merchantColor?: string) => {
  const backgroundColor = new Color(merchantColor ?? DEFAULT_BASE_COLOR)
  const primary = backgroundColor.toString({ format: 'hex' }).toUpperCase()
  const secondary = new Color(new Color(primary).lighten(0.85))
    .toString({ format: 'hex' })
    .toUpperCase()
  return {
    primary,
    secondary,
  }
}
