export const SecurityLockIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    fill="none"
    viewBox="0 0 17 17"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M4.625 14h7.75c.345 0 .625-.28.625-.625v-6.25a.625.625 0 0 0-.625-.625h-7.75A.625.625 0 0 0 4 7.125v6.25c0 .345.28.625.625.625M8.5 11.5V9m-2-2.5V4.25c0-.966.895-1.75 2-1.75s2 .784 2 1.75V6.5"
    ></path>
  </svg>
)
