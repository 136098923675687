import { env } from './env'

export const getContextFromLocation = (location: Location) => {
  const { GR4VY_ID, GR4VY_ENVIRONMENT } = env
  const { host: url, pathname } = location
  const paymentLinkId = pathname.split('/').pop()
  let gr4vyId = GR4VY_ID || 'dev'
  let environment: 'sandbox' | 'production' = GR4VY_ENVIRONMENT || 'sandbox'

  if (
    !GR4VY_ID &&
    !GR4VY_ENVIRONMENT &&
    url.match(/pay(\.sandbox|)\..*\.gr4vy\.app/g)
  ) {
    const host = url.split('.').reverse()
    gr4vyId = host[2]

    if (host[2] !== 'dev') {
      environment = host[3] === 'sandbox' ? 'sandbox' : 'production'
    }
  }

  return { gr4vyId, environment, paymentLinkId }
}
