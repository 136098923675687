import Color from 'colorjs.io'
import { WHITE_COLOR } from './get-button-text-color'
import { getLightnessContrast } from './get-lightness-contrast'

export function adjustColorForContrast(color: string | Color): string {
  let foregroundColor = new Color(color)
  let currentContrast = getLightnessContrast(foregroundColor, WHITE_COLOR)

  while (currentContrast < 75) {
    foregroundColor = new Color(foregroundColor.darken())
    currentContrast = getLightnessContrast(foregroundColor, WHITE_COLOR)
  }

  return foregroundColor.toString({ format: 'hex' })
}

export const getLinkColor = (baseColor: string) => {
  const foregroundColor = new Color(baseColor)

  return getLightnessContrast(foregroundColor, WHITE_COLOR) < 75
    ? adjustColorForContrast(baseColor)
    : foregroundColor.toString({ format: 'hex' }).toUpperCase()
}
