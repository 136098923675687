import { EmbedInstance } from '@gr4vy/embed-react'
import { useRouteContext } from '@tanstack/react-router'
import clsx from 'clsx'
import { CSSProperties, RefObject } from 'react'
import { useIntl } from 'react-intl'
import { SecurityLockIcon } from '@/assets/images/icons/SecurityLock'
import { Button, Heading, Embed } from '@/components'
import {
  getContextFromLocation,
  formatCurrency,
  EmbedEvent,
  getButtonTextColor,
  DEFAULT_BASE_COLOR,
  getLinkColor,
  getSpinnerColor,
  pick,
} from '@/utils'

const { gr4vyId, environment } = getContextFromLocation(window.location)

export interface PayWithProps {
  embedRef: RefObject<EmbedInstance>
  handleEmbedSubmit: () => void
  handleEmbedEvent: <E extends keyof EmbedEvent>(
    event: E,
    data: EmbedEvent[E]
  ) => void
}

export const PayWith = ({
  embedRef,
  handleEmbedSubmit,
  handleEmbedEvent,
}: PayWithProps) => {
  const intl = useIntl()
  const payWith = intl.formatMessage({
    id: 'PAY_WITH',
    defaultMessage: 'Pay with',
  })
  const termsAndConditions = intl.formatMessage({
    id: 'TERMS_CONDITIONS',
    defaultMessage: 'Terms and conditions',
  })
  const apply = intl.formatMessage({
    id: 'APPLY',
    defaultMessage: 'apply',
  })
  const pay = intl.formatMessage({
    id: 'PAY',
    defaultMessage: 'Pay',
  })

  const { paymentLink } = useRouteContext({ from: '/link/$id' })
  if (!paymentLink) return null

  const {
    amount,
    cartItems,
    country,
    currency,
    embedToken,
    externalIdentifier,
    intent,
    merchantTermsAndConditionsUrl,
    merchantColor,
    metadata,
    merchantAccountId,
    paymentSource,
    statementDescriptor,
    shippingDetails,
    ...rest
  } = paymentLink

  const baseColor = merchantColor || DEFAULT_BASE_COLOR
  const buttonTextColor = getButtonTextColor(baseColor)
  const linkColor = getLinkColor(baseColor)
  const spinnerColor = getSpinnerColor(baseColor)

  let buyer = null
  if (rest.buyer) {
    buyer = pick(
      {
        ...rest.buyer,
        shippingDetails: shippingDetails
          ? pick(shippingDetails, [
              'firstName',
              'lastName',
              'emailAddress',
              'address',
            ])
          : null,
      },
      ['displayName', 'externalIdentifier', 'billingDetails', 'shippingDetails']
    )
  }

  return (
    <section
      className="hidden flex-col gap-24 md:flex md:basis-1/2 md:p-32"
      style={
        {
          '--color-merchant': baseColor,
          '--color-text': buttonTextColor.idle,
          '--color-link': linkColor,
          '--color-hover': buttonTextColor.hover,
        } as CSSProperties
      }
    >
      <div className="flex flex-col gap-8">
        <Heading icon="wallet">{payWith}</Heading>
        <Embed
          forwardedRef={embedRef}
          gr4vyId={gr4vyId}
          environment={environment}
          amount={amount}
          buyer={buyer}
          country={country}
          currency={currency}
          externalIdentifier={externalIdentifier}
          intent={intent}
          metadata={metadata}
          paymentSource={paymentSource}
          statementDescriptor={statementDescriptor}
          merchantAccountId={merchantAccountId}
          cartItems={cartItems}
          token={embedToken}
          onEvent={handleEmbedEvent}
          theme={{
            colors: {
              primary: linkColor,
              info: spinnerColor.primary,
              infoBackground: spinnerColor.secondary,
            },
            fonts: {
              body: 'google:Roboto',
            },
            radii: {
              container: 'rounded',
              input: 'rounded',
            },
          }}
        />
      </div>
      {merchantTermsAndConditionsUrl && (
        <section id="terms" className="flex">
          <a
            href={merchantTermsAndConditionsUrl}
            className={clsx('text-[--color-link]', 'inline font-bold')}
          >
            {termsAndConditions}
          </a>
          <span>&nbsp;{apply}</span>
        </section>
      )}
      <Button onClick={handleEmbedSubmit}>
        <div className="flex items-center gap-8 text-[--color-text]">
          <SecurityLockIcon />
          <span>
            {pay}
            &nbsp;
            {formatCurrency(amount, {
              currency,
            })}
          </span>
        </div>
      </Button>
    </section>
  )
}
